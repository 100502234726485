<template>
    <div v-if="error_connect">
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title">Wallet connection</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" @click="close()">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body" style="text-align: left;">
                                <p>Click the button below and open it in:</p>
                             
                                <button class="btn" @click="openBitgetWallet" style="background-color: #222322; width: 318px; height: 62px;" >
                                    <img src="https://static-web.jjdsn.vip/17c3dc65b04a52709561f1c2f7d0ccd8/img/9f0c375dcd93.png" style="width: 200px; cursor: pointer; margin: 0 auto;" />

                                    
                                </button>

                                <button class="btn" @click="openOKXWallet" style="background-color: #222322; width: 318px; height: 62px; margin-top: 15px; " >
                                    <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAKQAAABICAQAAADSOpYzAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAAFiUAABYlAUlSJPAAAAAHdElNRQfmBwMNKhI4Vg/yAAABMklEQVR42u3aQW6DMBBAUZyT5eSFE/RInS5psOKx8Scq0n9ZNXjA+VE3VpZFkiRJkiShYo0rrYPPWyf3+3VYXZL138nTuudLxLVfVCmHrcXY+uqjteejPF7/XH5G1teXe+ebt1E/Q0IMCTEkxJAQQ0IMCTEkxJAQQ0IMCTEkxJCQ+4XcBq+Orj87n54oPlv3iWc2Xk3A55tzZs8rd+l55OT54PB5ZLrhMjd/2M3keeXufv/a/5QhIYaEGBJiSIghIYaEGBJiSIghIYaEGBJiSEgaMjtG+/B+tw/f8eVqlPq1X7xYlX5wfTXfPj/095Hv1tdfRPLJ/X3kvRkSYkiIISGGhBgSYkiIISGGhBgSYkiIISGGhDwuOOH7a+t4p//qmflticYrf946OS9JkiRJkk75BUgSur6QrNyYAAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIyLTA3LTAzVDEzOjQyOjE4KzAwOjAwKpbwNQAAACV0RVh0ZGF0ZTptb2RpZnkAMjAyMi0wNy0wM1QxMzo0MjoxOCswMDowMFvLSIkAAAAASUVORK5CYII=" 
                                
                                    style="width: 90px; cursor: pointer; margin: 0 auto;" />

                                    
                                </button>

                                <button class="btn" @click="openMetamask" style="background-color: #222322; width: 318px; height: 62px; margin-top: 15px; " >
                                    <img src="https://freelogopng.com/images/all_img/1683020860metamask-logo-white.png" 
                                
                                    style="width: 150px; cursor: pointer; margin: 0 auto;" />

                                    
                                </button>
                                
                                
                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "ErrorConnect",
    props: ['error_connect'],
    methods: {
        close() {
            this.$emit('close', false);
        },
        openBitgetWallet() {
            window.location = "https://bkcode.vip?action=dapp&url=https://nft.incaswap.com/#/mint";
        },
        openOKXWallet() {
            const dappUrl = 'https://nft.incaswap.com/#/mint';
            const encodedDappUrl = encodeURIComponent(dappUrl);
            const deepLink = `okx://wallet/dapp/url?dappUrl=${encodedDappUrl}`;
            const encodedUrl = `https://www.okx.com/download?deeplink=${encodeURIComponent(deepLink)}`;
            window.location.href = encodedUrl;
        },

        openMetamask() {
            window.location = "https://metamask.app.link/dapp/nft.incaswap.com/#/mint";
        },
    }
}
</script>
